.mapa {
    height: 100vh;
    overflow: hidden;
    position: relative;

    iframe {
        position: absolute;
        top: 0;left: 0;
        right: 0;
        bottom: 0;
        border: none;
        width: 100%;
        height: 100%;
    }



    &__title {
        position: absolute;
        left: 0;
        top: 20vh;
        padding: 0 40px;
        height: 40px;
        line-height: 40px;
        background: $blue;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 2;
        color: $white;
        font-family: $altFont;
        font-size: 24px;
        text-transform: uppercase;
        margin: 0;

        @include mq(md) {
            height: 60px;
            line-height: 60px;
            font-size: 30px;
            top: 21vh;
        }
    }

    &__bilz,
    &__pap {
        position: absolute;
        left: 0;
        top: 60%;
        transform: translateY(-50%);
        z-index: 2;
        width: 35%;
        
        @include mq(md) {
            width: 15%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__pap {
        left: auto;
        right: 0;
    }

    &__searchbar {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        min-width: 90%;
        top: 28vh;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        background: $white;
        border-radius: 50px;
        z-index: 2;
        color: $white;
        font-family: $altFont;
        font-size: 30px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        @include mq(md) {
            min-width: 30%;
            top: 21vh;
            font-size: 30px;
            height: 60px;
            line-height: 60px;
        }

        i {
            color: $black;
            z-index: 2;
            margin-right: 10px;
            font-size: 16px;
            cursor: pointer;

            @include mq(md) {
                margin-right: 20px;
                font-size: 24px;
            }
        }

        input {
            height: 40px;
            background: transparent;
            border: none;
            outline: none;
            font-size: 10px;
            text-transform: uppercase;
            width: 100%;
            font-weight: 500;

            @include mq(md) {
                height: 60px;
                font-size: 12px;
            }
        }
    }

    &__pins {
        
        img {
            position: absolute;
            z-index: 2;
            cursor: pointer;

            &:nth-child(1) {
                top: 55%;
                left: 25%;
            }

            &:nth-child(2) {
                top: 40%;
                left: 35%;
            }

            &:nth-child(3) {
                top: 61%;
                left: 40%;
            }

            &:nth-child(4) {
                top: 35%;
                left: 50%;
            }

            &:nth-child(5) {
                top: 45%;
                left: 60%;
            }

            &:nth-child(6) {
                top: 65%;
                left: 65%;
            }
        }
    }

    &__add-button {
        position: absolute;
        bottom: -100px;
        width: 200px;
        height: 200px;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        background: $yellow;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 35px;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: none;

        @include mq(md) {
            right: 80px;
            left: auto;
            transform: translateX(0);
        }

        img {
            height: 50px;
            width: 50px;
            position: absolute;
            top: -15px;
            z-index: 3;
        }

        p {
            margin: 0;
            color: $red;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 14px;
            font-family: $altFont;
        }

        span {
            color: $black;
            text-transform: uppercase;
            font-weight: 500;
            font-family: $altFont;
            font-size: 18px;
        }
    }

    &__form {
      background: $red;
      display:none;
      position: absolute;
      padding: 30px;
      border-radius: 10px;
      right: 50px;
      bottom: 150px;
      width: 90%;
      max-width: 350px;
      z-index: 5;

      &:after {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        bottom: 0;
        right: 25%;
        transform: translateY(50%) rotate(45deg);
        background: $red;
      }

      &__title {
        color: $white;
        font-weight: 500;
      }

      &__group {
          width: 100%;
      }

      &__input {
          width: 100%;
          height: 50px;
          border-radius: 10px;
          margin-bottom: 15px;
          padding: 20px;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 500;
          border: 0;
          outline: none;
          font-family: $altFont;
      }

      &__button {
          border: none;
          text-align: center;
          position: relative;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 80%;
          height: 60px;
          overflow: hidden;
          line-height: 55px;
          text-decoration: none;
          margin-top: 20px;
          background: transparent;
          cursor: pointer;

          span {
              position: absolute;
              box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
              top: -30px;
              left: 0;
              right: 0;
              bottom: 4px;
              background: $yellow;
              transform: skewY(-2deg);
          }

          p {
              font-family: $altFont;
              color: $black;
              z-index: 2;
              position: relative;
              text-transform: uppercase;
              font-size: 20px;
              margin: 0;
              font-weight: 500;
          }
      }

      &.-visible {
        display: block;
      }
  }
}