.reciclaje {
    background: url('/images/reciclado/back-mobile.jpg') no-repeat center 20vh / contain, $yellow;
    overflow: hidden;
    
    @include mq(md) {
        margin-bottom: -25vh;
        background: url('/images/reciclado/back.jpg') no-repeat center top / cover;

    }

    &__hero {
        padding-top: 20vh;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        height: 100vh;
        
        @include mq(md) {
            justify-content: center;
            padding-top: 10vh;
        }

        img {
            position: absolute;
            top: 75%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            display: none;
            
            @include mq(md) {
                display: block;
            }
        }

        &__title {
            text-align: center;
            text-transform: uppercase;
            margin: 0;
            color: $white;
            font-family: $altFont;
            font-size: 70px;
            line-height: .7;
            padding-top: 40px;

            @include mq(md) {
                font-size: 150px;
                padding-top: 0;
            }

            span {
                display: block;
                color: $red;
                font-size: 30px;
                
                @include mq(md) {
                    font-size: 50px;
                }
            }
        }

        &__container {
            padding: 40px 0;
            position: relative;
            width: calc(100% - 30px);
            
            @include mq(md) {
                width: 35%;
                padding: 80px 0;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 100%;
                height: auto;
                display: none;

                @include mq(md) {
                    display: block;
                }
            }
        }

        &__text {
            text-align: center;
            margin: 0;
            z-index: 2;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            font-weight: 500;
            font-size: 20px;

            @include mq(md) {
                font-size: 18px;
                width: 67%;
                font-size: 20px;
            }

            br {
                display: none;

                @include mq(md) {
                    display: block;
                }
            }
        }
    }

    &__mapa {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include mq(md) {
            height: 100vh;
            flex-direction: row;
            padding: 0 10%;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
        }

        &__container {
            width: 100%;

            &--left {
                order: 2;

                @include mq(md) {
                    width: 30%;
                    order: 1;
                }
            }

            &--right {
                order: 1;
                margin: 20px 0;

                @include mq(md) {
                    width: 70%;
                    margin: 0;
                    order: 2;
                }
            }
        }

        &__image {
            width:  100%;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            color: $white;
            font-family: $altFont;
            text-transform: uppercase;
            line-height: .7;
            margin: 0;
            margin-bottom: 20px;
            font-size: 60px;

            @include mq(md) {
                font-size: 100px;
            }

            span {
                display: block;
                color: $blue;
            }
        }

        &__text {
            text-transform: uppercase;
            color: $white;
            font-weight: 500;
            font-family: $altFont;
            font-size: 18px;
            margin: 0;
            
            @include mq(md) {
                margin-bottom: 40px;
                font-size: 20px;
            }
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            overflow: hidden;
            line-height: 45px;
            text-decoration: none;
            width: 40%;
            margin-top: 20px;
            
            @include mq(md) {
                height: 60px;
                line-height: 55px;
                width: 80%;
            }
            
            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $blue;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                
                @include mq(md) {
                    font-size: 20px;
                }
            }
        }
    }

    &__creaciones {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        
        @include mq(md) {
            height: 100vh;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            padding: 0 10%;
        }

        &__container {
            width: 100%;

            &:first-child {
                margin-bottom: 20px;

                @include mq(md) {
                    margin-bottom: 0;
                }
            }

            @include mq(md) {
                width: 50%;
            }
        }

        &__image {
            width: 70%;
            margin-left: auto;
            margin-right: auto;

            @include mq(md) {
                width:  100%;
            }

            img {
                width: 100%;
                height: auto;

                @include mq(md) {
                    width: auto;
                    height: 70%;
                }
            }
        }

        &__title {
            color: $white;
            font-family: $altFont;
            text-transform: uppercase;
            line-height: .7;
            margin: 0;
            margin-bottom: 20px;
            font-size: 60px;
            
            @include mq(md) {
                font-size: 100px;
            }
        }

        &__text {
            text-transform: uppercase;
            color: $white;
            font-weight: 500;
            font-family: $altFont;
            margin: 0;
            margin-bottom: 40px;
            font-size: 16px;

            @include mq(md) {
                font-size: 20px;
            }
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            overflow: hidden;
            text-decoration: none;
            margin-top: 20px;
            width: 30%;
            line-height: 45px;
            
            @include mq(md) {
                line-height: 55px;
                height: 60px;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $red;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                margin: 0;
                font-weight: 500;
                font-size: 14px;
                
                @include mq(md) {
                    font-size: 20px;
                }
            }
        }
    }

    &__ideas {
        padding: 10px 15px;
        padding-bottom: 50px;
        position: relative;
        display: flex;
        flex-direction: column;

        @include mq(md) {
            flex-direction: row;
            align-items: center;
            min-height: 100vh;
            padding-bottom: 0;
            justify-content: space-between;
            flex-wrap: nowrap;
            padding: 0 10%;
        }

        &__container {
            width: 100%;

            &--left {
                order: 2;

                @include mq(md) {
                    width: 31%;
                    order: 1;
                }
            }

            &--right {
                order: 1;
                margin: 0 0 20px 0;

                @include mq(md) {
                    width:67%;
                    margin: 0;
                    order: 2;
                }
            }
        }

        &__image {
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            @include mq(md) {
                width:  100%;
            }
            
            img {
                width: 100%;
                height: auto;
            }
        }

        &__title {
            color: $white;
            font-family: $altFont;
            text-transform: uppercase;
            line-height: .7;
            margin: 0;
            margin-bottom: 20px;
            font-size: 60px;

            @include mq(md) {
                font-size: 100px;
            }

            span {
                display: block;
                color: $yellow;
                font-size: 110px;
                
                @include mq(md) {
                    font-size: 160px;
                }
            }
        }

        &__text {
            text-transform: uppercase;
            color: $white;
            font-weight: 500;
            font-family: $altFont;
            font-size: 16px;
            margin: 0;
            margin-bottom: 20px;
            
            @include mq(md) {
                margin-bottom: 40px;
                font-size: 20px;
            }
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 40%;
            overflow: hidden;
            line-height: 45px;
            text-decoration: none;
            margin-top: 20px;

            @include mq(md) {
                height: 60px;
                line-height: 55px;
                width: 60%;
            }
            
            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $yellow;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 16px;
                margin: 0;
                font-weight: 500;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }
    }

    &__tips {
        padding: 0 15px;
        min-height: 81vh;
        display: flex;
        flex-direction: column;        
        
        @include mq(md) {
            height: 140vh;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            padding: 0 10%;
        }

        &__container {
            width: 100%;

            @include mq(md) {
                width: 50%;
                margin-top: -20vh;
            }

            &:last-child {
                margin-bottom: 40px;

                @include mq(md) {
                    margin-bottom: 0;
                }
            }
        }

        &__image {
            width:  100%;

            img {
                width: 100%;

                @include mq(md) {
                    width: 90%;
                    height: auto;
                }
            }
        }

        &__title {
            color: $white;
            font-size: 70px;
            font-family: $altFont;
            text-transform: uppercase;
            line-height: .7;
            margin: 0;
            margin-bottom: 20px;

            @include mq(md) {
                font-size: 100px;
            }

            span {
                display: block;
                color: $blue;
            }
        }

        &__text {
            text-transform: uppercase;
            color: $white;
            font-weight: 500;
            font-family: $altFont;
            font-size: 16px;
            margin: 0;
            margin-bottom: 40px;

            @include mq(md) {
                font-size: 20px;
            }
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 50%;
            overflow: hidden;
            line-height: 45px;
            text-decoration: none;
            margin-top: 20px;
            
            @include mq(md) {
                height: 60px;
                line-height: 55px;
                width: 40%;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $green;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 14px;
                margin: 0;
                font-weight: 500;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }
    }

    &__idea-mano {
        position: absolute;
        right: 0;
        top: 20%;
        width: 35%;
        max-width: 300px;
        transform: translateY(-50%);

        @include mq(md) {
            width: 20%;
            top: 50%;
        }
    }
}