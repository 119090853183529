.mundo {
    padding: 20vh 0 40px;
    background: url('/images/mundo/mundo-mobile.jpg') no-repeat center top / cover;
 
    @include mq(md) {
      background: url('/images/diversion/mundo-back.jpg') no-repeat center top / cover;
      padding: 20vh 0;
    }

    &__hero {
        height: 90vh;
        padding-top: 4vh;
        position: relative;

        @include mq(md) {
            padding-top: 0;
            height: 100vh;
        }

        &__container {
            margin-right: auto;
            margin-left: auto;
            width: 90%;
            
            @include mq(md) {
                width: 40%;
            }
        }

        &__title {
            font-size: 90px;
            font-family: $altFont;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 35vh;
            color: $white;
            line-height: .7;

            @include mq(md) {
                font-size: 180px;
                margin: 0;
            }

            span {
                display: block;
                color: $yellow;
                font-size: 40px;

                @include mq(md) {
                    font-size: 70px;
                }
            }
        }

        &__text {
            color: $white;
            text-transform: uppercase;
            font-family: $altFont;
            text-align: center;
            font-size: 16px;
            
            @include mq(md) {
                font-size: 24px;
            }
        }
    }

    &__historia,
    &__campanas {
        height: 70vh;
        padding-left: 0;
        display: flex;
        align-items: center;

        @include mq(md) {
          padding-left: 10%;
        }

        &__container {

          width: 90%;
          margin: 0 auto 40px;
          
          @include mq(md) {
            margin: 0;
            width: 30%;
          }

          @include mq(lgHeight) {
            margin: 0 auto 15vh;
          }

        }

        &__title {
            color: $white;
            font-family: $altFont;
            text-transform: uppercase;
            font-size: 50px;
            margin: 0;
            margin-bottom: 20px;
            line-height: 1;

            @include mq(md) {
              font-size: 100px;
            }
        }

        &__text {
            margin: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-size: 16px;
            color: $white;
            font-family: $altFont;

            @include mq(md) {
              font-size: 20px;
              margin-bottom: 40px;
            }
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 100%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            
            @include mq(md) {
                width: 60%;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $yellow;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $darkestGray;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }
        }    
    }

    &__productos {
        height: 70vh;
        display: flex;
        align-items: flex-end;

        @include mq(md) {
          align-items: center;
          height: 70vh;
        }

        &__container {
          margin: 0 auto 40px;
          width: 80%;

          @include mq(md) {
            margin-left: 50%;
          }

          @include mq(lgHeight) {
            margin: 0 auto 15vh;
          }
        }

        &__title {
            color: $white;
            font-family: $altFont;
            text-transform: uppercase;
            font-size: 50px;
            margin: 0;
            margin-bottom: 20px;
            line-height: 1;

            @include mq(md) {
              font-size: 100px;
            }
        }

        &__text {
            margin: 0;
            margin-bottom: 40px;
            text-transform: uppercase;
            font-size: 20px;
            color: $white;
            font-family: $altFont;
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 100%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            
            @include mq(md) {
                width: 60%;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $blue;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $darkestGray;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }
        }    
    }

    &__campanas {
      align-items: flex-end;
      height: 80vh;

      @include mq(md) {
        align-items: center;
        height: 70vh;
      }

      &__container {
        margin: 0 auto 40px;

        @include mq(md) {
          margin: 0;
        }

        @include mq(lgHeight) {
          margin: 0 auto 15vh;
        }
      }

        &__button {
            span {
                background: $yellow;
            }
        }
    }
}