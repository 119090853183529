.campanas {
    background: url('/images/campanas/background.jpg') no-repeat center center / cover;
    position: relative;
    height: 120vh;
    padding-top: 30vh;
    
    @include mq(md) {
        height: 100vh;
        padding-top: 0;
    }

    &__container {
        z-index: 3;
        width: 95%;
        padding: 10px;
        background: $yellow;
        border-radius: 10px;
        height: 300px;
        margin-left: auto;        
        margin-right: auto;        

        @include mq(md) {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 45%;
            height: 390px;
            padding: 20px;
        }
    }

    &__slider {
        width: 100%;
    }

    .slick-slide,
    .slick-list {
        width: 100%;
    }

    .slick-prev {
        //left: -100px;
        top: 40%;
    }

    .slick-next {
        //right: -100px;
        top: 40%;
    }

    &__slide {
      width: 100%;
      position: relative;
      height: 280px;
      
        @include mq(md) {
            height: 350px;
        }

        iframe {
            width: 100%;
            height: 100%;
            border: none;
            margin-bottom: 30px;
        }

        img {
            margin-left: auto;
            margin-right: auto;
            width: auto;
            height: 100%;
            margin-bottom: 30px;
        }

        p {
            text-align: center;
            color: $white;
            font-size: 24px;
            text-transform: uppercase;
            margin: 0;

            @include mq(md) {
                font-size: 30px;
            }
        }
    }
}