.home {

    $h: &;

    position: relative;
    overflow: hidden;

    &__indicators {
        @include mq(md) {
            position: fixed;
            top: 50%;
            right: 80px;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 5000;
            cursor: pointer;

            a {
                display: block;
                margin: 15px 0;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: $white;

                &.-active {
                    width: 15px;
                    height: 15px;
                    background: $yellow;
                }
            }
        }
    }

    &__hero {
        height: 100vh;
        position: relative;
        overflow: hidden;

        &.js-scrolled {
            z-index: 2000;
        }

        &__slider {
            width: 100%;
            height: 100%;
        }

        .slick-slide,
        .slick-track,
        .slick-list {
            height: 100%;
            margin: 0;

            > div {
                width: 100%;
                height: 100%;
            }
        }

        .slick-prev {
            left: 10px;
        }

        .slick-next {
            right: 10px;
        }

        .slick-dots {
            display: none !important;
        }

        &__slide {
            width: 100%;
            height: 100%;
            position: relative;
            background: url('/images/home/hero.jpg') no-repeat center center / cover;

            &.-second {
                background: url('/images/home/hero-2.jpg') no-repeat center center / cover;

                img {
                    position: absolute;
                    top: 50%;
                    right: 150px;
                    transform: translate3d(0,-50%, 0);
                    width: auto;
                    height: 60%;
                }
            }

            &.-third {
                background: url('/images/home/hero-3.jpg') no-repeat center center / cover;
            }

            img {
                position: absolute;
                width: auto;
                height: 25%;
                                
                @include mq(md) {
                    height: 55%;
                }

                &.-align-left {
                    left: 0;
                    top: 65%;
                                        
                    @include mq(md) {
                        top: 30%;
                    }
                }

                &.-align-right {
                    right: 0;
                    top: 55%;                    
                    @include mq(md) {
                        top: 20%;
                    }
                }
            }
        }

        &__container {
            width: 90%;
            position: relative;
            text-align: center;
            top: 25%;            
            margin: auto;

            @include mq(md) {
                width: 50%;
                top: 45%;
            }

            &.-left {
                transform: translate3d(0,-50%, 0);
                margin-left: 15px; 

                @include mq(md) {
                  margin-left: 80px;
                    width: 35%;
                }

                #{$h}__hero__title,
                #{$h}__hero__text {
                    text-align: left;
                }
            }
        }

        &__title {
            text-transform: uppercase;
            color: $white;
            text-align: center;
            margin: 0;
            margin-bottom: 20px;
            font-size: 40px;
            font-family: $altFont;
            line-height: .9;
            font-weight: 500;

            @include mq(md){ 
                font-size: 70px;
            }
        }

        &__text {
            text-transform: uppercase;
            text-align: center;
            color: $white;
            font-size: 20px;
            margin: 0;
            font-weight: 500;
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 40%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            
            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $yellow;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }
        }

        &__scroll-container{ 
            position: absolute;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background: $yellow;
            right: 20px;
            z-index: 2000;
            bottom: -20px;
            
            @include mq(md) {
                bottom: -30px;
                width: 200px;
                height: 200px;
                right: 80px;
            }
        }

        &__arrow-icon {
            position: absolute;
            top: -20px;
            width: 25%;
            left: 0;
            right: 0;
            margin: auto;
            animation: arrowDown .8s ease infinite forwards;

            img {
                width: 100%;
                height: auto;
            }
        }

        &__scroll-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            margin: 0;
            width: 70%;
            font-size: 12px;
            color: $red;
            font-weight: 500;
            text-transform: uppercase;
            
            @include mq(md) {
                font-size: 16px;
            }
            
            span {
                line-height: .8;
                display: block;
                color: $black;
                font-size: 12px;

                @include mq(md) {
                    font-size: 24px;
                }
            }
        }
    }

    &__reciclaje,
    &__diversion,
    &__mundo {
        height: 100vh;
        background: $red;
        padding-top: 100px; 

        &__title {
            text-transform: uppercase;
            text-align: center;
            font-size: 48px;
            font-weight: 500;
            color: $white;
            margin: 0;

            @include mq(md) {
                font-size: 120px;
            }
        }
    }

    &__reciclaje {
        background: url('/images/home/reciclaje-mobile.jpg') no-repeat center center / cover;
        position: relative;

        @include mq(md) {
          background: $green;

        }

        &__cta {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 60%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            margin: auto;
            
            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $yellow;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }

            @include mq(md) {
              display: none;
            }
        }

        &__stage {
          position: absolute;
          top:0;
          left:0;
          right:0 ;
          bottom: 0;
          display: none;

          img {
            position: absolute;
            width: 110%;
            left:-5% !important;
            top: 0;
          }

          @include mq(md) {
            display: block;
          }
        }

        &__tooltips {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: none;

            @include mq(md) {
              display: block;
            }
        }
        
        &__tooltip {
            position: absolute;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
                @include trans($d: .5s);
                position: absolute;

                &.-open {
                    opacity: 0;
                    pointer-events: none;
                    transform: scale3d(.3,.3,1);
                    animation: tooltipClose .5s ease forwards;
                }
            }

            &:hover {
                img {
                    &.-close {
                        opacity: 0;
                    }
                    
                    &.-open {
                        opacity: 1;
                        pointer-events: all;
                        animation: tooltipOpen .5s ease forwards;
                    }
                }
            }
    
            &:nth-child(1) {
                top: 75%;
                left: 23%;
            }
            &:nth-child(2) {
                top: 80%;
                left: 38%;
            }
            &:nth-child(3) {
                top: 36%;
                left: 57%;
            }
            &:nth-child(4) {
                top: 70%;
                left: 75%;
            }
            &:nth-child(5) {
                top: 90%;
                left: 80%;
            }
        }
    }

    &__diversion {
        background: url('/images/home/diversion-mobile.jpg') no-repeat center center / cover;
        position: relative;

        @include mq(md) {
          background: $green;
        }

        &__stage {
          position: absolute;
          top:0;
          left:0;
          right:0 ;
          bottom: 0;
          display: none;

          img {
            position: absolute;
            width: 110%;
            left:-5% !important;
            top: 0;
          }

          @include mq(md) {
            display: block;
          }
        }

        &__tooltips {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: none;

            @include mq(md) {
              display: block;
            }
        }
        
        &__tooltip {
            position: absolute;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
                @include trans($d: .5s);
                position: absolute;

                &.-open {
                    opacity: 0;
                    pointer-events: none;
                    transform: scale3d(.3,.3,1);
                    animation: tooltipClose .5s ease forwards;
                }
            }

            &:hover {
                img {
                    &.-close {
                        opacity: 0;
                    }
                    
                    &.-open {
                        opacity: 1;
                        pointer-events: all;
                        animation: tooltipOpen .5s ease forwards;
                    }
                }
            }

            &:nth-child(1) {
                top: 36%;
                left: 40%;
            }
            &:nth-child(2) {
                top: 80%;
                left: 38%;
            }
            &:nth-child(3) {
                top: 74%;
                left: 53%;
            }
            &:nth-child(4) {
                top: 55%;
                left: 65%;
            }
            &:nth-child(5) {
                top: 90%;
                left: 73%;
            }
        }
    }

    &__mundo {
        background: $black url('/images/home/mundo-mobile.jpg') no-repeat center top / 105%;
        position: relative;
        height: 90vh;

        @include mq(md) {
          background: $black;
          height: 100vh;
        }

        &__stage {
          position: absolute;
          top:0;
          left:0;
          right:0 ;
          bottom: 0;
          display: none;

          img {
            position: absolute;
            width: 110%;
            left:-5% !important;
            top: 0;

            &:nth-child(2) {
              animation: stars 3s ease infinite;
            }

            &:nth-child(5) {
              animation: float 1s ease infinite;
            }

            &:nth-child(6) {
              animation: float 2s ease infinite;
            }
          }

          @include mq(md) {
            display: block;
          }
        }

        &__tooltips {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            display: none;

            @include mq(md) {
              display: block;
            }
        }
        
        &__tooltip {
            position: absolute;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
                @include trans($d: .5s);
                position: absolute;

                &.-open {
                    opacity: 0;
                    pointer-events: none;
                    transform: scale3d(.3,.3,1);
                    animation: tooltipClose .5s ease forwards;
                }
            }

            &:hover {
                img {
                    &.-close {
                        opacity: 0;
                    }
                    
                    &.-open {
                        opacity: 1;
                        pointer-events: all;
                        animation: tooltipOpen .5s ease forwards;
                    }
                }
            }

            &:nth-child(1) {
                top: 55%;
                left: 20%;
            }
            &:nth-child(2) {
                top: 75%;
                left: 50%;
            }
            &:nth-child(3) {
                top: 75%;
                left: 80%;
            }
        }
    }
}

.-red {
    color: $red;
}

.-black {
     color: $black;
}

@keyframes tooltipOpen {
    0% {
        transform: scale3d(0.3,0.3,1);
    }
    90% {
        transform: scale3d(1.1,1.1,1);
    }
    100% {
        transform: scale3d(1,1,1);
    }
}

@keyframes tooltipClose {
    0% {
        transform: scale3d(1,1,1);
    }
    10% {
        transform: scale3d(1.1,1.1,1);
    }
    100% {
        transform: scale3d(0.3,0.3,1);
    }
}

@keyframes arrowDown {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(10px);
    }
}

@keyframes float {
  0%{
      top:0;
  }
  50%{
      top:2%;
  }
  100%{
    top:0;
  }
}

@keyframes stars {
  0%{
      filter: brightness(100%);
      opacity: .7;
  }
  80%{
      filter: brightness(300%);
      opacity: 1;
  }
  100%{
    filter: brightness(100%);
    opacity: .7;
  }
}