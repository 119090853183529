.diversion-en-familia {
    &__hero {
        height: 60vh;

        @include mq(md) {
            height: 80vh;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            z-index: -1;
        }

        &__slider {
            width: 100%;
            height: 100%;
            margin-bottom: 0 !important;
            position: relative;
            z-index: 10;
        }

        .slick-slide,
        .slick-track,
        .slick-list {
            height: 100%;
        }

        .slick-prev {
            left: 18%;
            bottom: 50px;
            top: auto;
        }

        .slick-next {
            right: 18%;
            bottom: 50px;
            top: auto;
        }

        &__container {
            width: 90%;
            padding: 15px;
            background: $white;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 3;

            @include mq(md) {
                min-height: 230px;
                width: 60%;
                padding: 40px 60px;
            }

            h3 {
                color: $red;
                text-transform: uppercase;
                font-size: 24px;
                margin: 0;
                margin-bottom: 20px;

                @include mq(md) {
                    font-size: 30px;
                }
            }

            p {
                text-transform: uppercase;
                color: $darkestGray;
                margin: 0;
                font-size: 14px;

                @include mq(md) {
                    font-size: 16px;
                }
            }
        }
    }

    &__gallery {
        background: url('/images/familia/bottom-bg.png') no-repeat center top / cover;
        position: relative;
        padding-bottom: 200px;
        padding-top: 50px;
        z-index: 2;
        
        @include mq(md) {
            padding-top: 210px;
            min-height: 160vh;
            margin-top: -110px;

        }

        .-clouds {
            position: absolute;
            top: 30%;
            width: 100%;
            animation: clouds 60s linear infinite;
            opacity: 0;

            @include mq(md) {
                top: 20%;
            }
        }

        &__title {
            color: $red;
            text-transform: uppercase;
            font-size: 60px;
            font-family: $altFont;
            margin-top: 40px;
            text-align: center;
            margin-bottom: 40px;
            z-index: 2;
            position: relative;
        }

        &__slider {
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            @include mq(md) {
                width: 70%;
            }
        }

        .slick-slide {
            margin: 0 15px;

            .diversion-en-familia__gallery__slide {
                position: relative;
    
                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: calc(100% - 30px);
                    border: 5px solid $white;
                    background: $red;
                    transform: translate3d(-50%, -50%, 0) scale3d(1.02,1.02,1.02) rotate(3deg);
                    border-radius: 5px;
                }
            }
            
            &:nth-child(3n + 2) {
                .diversion-en-familia__gallery__slide:after {
                    background: $green;
                }
            }

            &:nth-child(3n + 3) {
                .diversion-en-familia__gallery__slide:after {
                    background: $blue;
                }
            }
        }

        .slick-prev {
            left: -150px;
        }

        .slick-next {
            right: -150px;
        }

        &__image {
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            position: relative;
            border-radius: 5px;
            border: 5px solid $white;
            z-index: 2;
            margin: 15px 0;       

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                min-width: 100%;
                min-height: 100%;
                z-index: 2;
            }
        }
    }
}

@keyframes clouds {
    0%{
        left: 100vw;
        opacity: 0;
    }
    5%{
        left: 100vw;
        opacity: 1;
    }
    95%{
        left: -100vw;
        opacity: 1;
    }
    100%{
        left: -100vw;
        opacity: 0;
    }
}