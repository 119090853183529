.footer {
    height: 20vh;
    width: 100%;
    padding: 0 15px;
    background: $yellow;

    @include mq(md) {
        padding: 0 80px;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }

    &__logo {
        height: 70%;
        z-index: 1000;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top: 0;
        left: 0; width: 100%;
        height: 100vh;
        background: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);

        @include mq(md) {
            justify-content: flex-start;
            transform: translateX(0);
            position: static;
            top: auto;
            left: auto;
            height: auto;
            background: transparent;
        }
    }

    &__list {
        
        @include mq(md) {
            display: flex;
        }
    }

    &__item {
        background: $darkYellow;
        padding: 10px 30px;
        border-radius: 50px;
        text-align: center;
        margin: 20px 0;

        @include mq(md) {
            margin: 0;
            margin-left: 20px;
        }

        a {
            text-decoration: none;
            text-transform: uppercase;
            color: $black;
            font-weight: 500;
            font-size: 14px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        z-index: 1000;
    }

    &__social-list {
        display: flex;
        margin-left: 15px;
    }

    &__social-item {
        margin-left: 20px;
        height: 40px;
        width: 40px;
        border-radius: 50%;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
        }

        i {
            color: $white;
        }

        &:nth-child(1) {
            background: $blue;
        }

        &:nth-child(2) {
            background: $black;
        }

        &:nth-child(3) {
            background: $red;
        }
    }
}
