.header {

    $h: &;

    position: absolute;
    top: 0;
    left: 0;
    height: 20vh;
    width: 100%;
    padding: 0 15px;
    z-index: 1000;

    @include mq(md) {
        padding: 0 80px;
    }

    &.js-scrolled {
        position: fixed;
        height: 10vh;
        background: $yellow;
        top: 0;
        left: 0;
        width: 100%;

        #{$h}__logo {
            height: 69.99%;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        height: 100%;
        justify-content: center;
    }

    &__logo {
        height: 70%;
        z-index: 1000;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top: 0;
        left: 0; width: 100%;
        height: 100vh;
        background: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);

        @include mq(md) {
            justify-content: flex-start;
            transform: translateX(0);
            position: static;
            top: auto;
            left: auto;
            height: auto;
            background: transparent;
        }
    }

    &__list {
        
        @include mq(md) {
            display: flex;
        }
    }

    &__item {
        @include trans;
        
        background: $darkYellow;
        border-radius: 50px;
        text-align: center;
        margin: 20px 0;
        height: 40px;
        
        @include mq(md) {
            margin: 0;
            margin-left: 20px;
        }


        &:hover {
            background: $darkYellow + #333;
            transform: scale3d(1.05, 1.05, 1.05);
            
            a {
                color: $red;
            }
        }
        
        a {
            @include trans;

            padding: 10px 30px;
            height: 40px;
            line-height: 40px;
            text-decoration: none;
            text-transform: uppercase;
            color: $black;
            font-weight: 500;
            font-size: 16px;
            font-family: $altFont;
            white-space: nowrap;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        z-index: 1000;
    }

    &__social-list {
        display: flex;
        margin-left: 15px;
    }

    &__social-item {
        margin-left: 5px;
        height: 40px;
        width: 40px;
        border: 3px solid $white;
        border-radius: 50%;
        
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-decoration: none;
        }

        i {
            color: $white;
        }

        &:nth-child(1) {
            background: $blue;
        }

        &:nth-child(2) {
            background: $black;
        }

        &:nth-child(3) {
            background: $red;
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        margin: auto;
        margin-left: 20px;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $white;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
