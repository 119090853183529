  .ideas {
    &__hero {
        height: 120vh;
        background: #7ccee6 url('/images/reciclado/idea-hero-bg.jpg') no-repeat center bottom / 150%;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        align-items: flex-start;

        @include mq(md) {
          align-items: center;
            padding-left: 80px;

            background: url('/images/reciclado/idea-hero-bg.jpg') no-repeat left top / cover;
        }

        &__container {
            width: 100%;
            position: relative;
            top: 18%;

            @include mq(md) {
                top: 0;
                width: 40%;
            }
        }

        &__title {
            color: $red;
            text-transform: uppercase;
            font-family: $altFont;
            font-size: 40px;
            margin: 0;
            margin-bottom: 20px;

            @include mq(md) {
                font-size: 80px;
            }
        }

        &__text {
            color: $darkestGray;
            text-transform: uppercase;
            font-family: $altFont;
            font-size: 16px;
            margin: 0;
            width: 75%;

            @include mq(md) {
                font-size: 20px;
            }
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 100%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            
            @include mq(md) {
                width: 40%;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $red;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $yellow;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }
        }    
    }
    &__content {
        background: url('/images/reciclado/idea-bottom-bg.png') no-repeat center top / cover;
    }

    &__participar {
        margin-top: -50vh;
        padding-top: 50vh;

        @include mq(md) {
          margin-top: -25vh;
          padding-top: 30vh;
        }

        &__title,
        &__text {
            color: $red;
            font-size: 60px;
            text-transform: uppercase;
            text-align: center;
            font-family: $altFont;
            margin: 0;
        }

        &__text {
            font-size: 26px;
            margin-bottom: 40px;            
        }

        &__form {
            padding: 0 15px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            margin-bottom: 50px;

            @include mq(md) {
              width: 60%;
            }

            img {
                position: absolute;
                left: 50%;
                top: 10%;
                transform: translate3d(-50%, -50%, 0);
                pointer-events: none;
                display: none;

                @include mq(md) {
                  display: block;
                }
            }

            input,
            textarea {
              font-family: $altFont;
              font-weight: 500;
              font-size: 16px;
            }
        }


        &__group {
            width: 49%;
            margin-bottom: 15px;

            &--textarea {
                width: 100%;
            }

            &--blue {
                border-radius: 5px;
                width: 100%;
                background: $blue;
                display: flex;
                justify-content: space-between;
                padding: 20px 40px;
                align-items: center;
                flex-wrap: wrap;

                p {
                    color: $white;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-family: $altFont;
                    margin: 0 0 15px;
                }
            }
        }

        &__input {
            width: 100%;
            height: 50px;
            border: none;
            border-radius: 5px;
            text-transform: uppercase;
            font-size: 12px;
            padding: 20px;
            color: $darkestGray;
            font-weight: 500;

            &--textarea {
                height: 200px;
                resize: none;
            }

            &--file {
                position: absolute;
                left: -9999px;
            }
        }

        &__label {
            display: block;
            height: 50px;
            line-height: 50px;
            background: $red;
            border-radius: 5px;
            padding: 0 40px;
            color: $white;
            text-transform: uppercase;
            font-weight: 500;
            cursor: pointer;
        }
    }

    &__gallery {
        padding-top: 50px;
        padding-bottom: 50px;

        @include mq(md){
          padding-top: 100px;
          padding-bottom: 100px;
        }

        &__title {
            color: $red;
            font-size: 36px;
            text-transform: uppercase;
            text-align: center;
            font-family: $altFont;
            margin: 0;
            margin-bottom: 40px;

            @include mq(md){
              font-size: 60px;
            }
        }

        &__container {
          padding: 0 15px;
          margin-left: auto;
          margin-right: auto;
          
          @include mq(md){
              width: calc(100% - 160px);
              display: flex;
              justify-content: space-between;
            }
        }

        &__slider {
            width: 100%;
        }

        &__slide {
          margin-bottom: 30px;

          &:not(:last-child) {
            @include mq(md) {
              margin-right: 15px;
            }
          }
        }

        .slick-slide {
            padding: 0 15px;
        }

        .slick-dots {
            bottom: -50px;

            .slick-active {

                button {
                    &:before {
                        color: $red;
                        font-size: 18px;
                    }
                }
            }
            li {
                button {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &__idea {
            background: $white;
            padding: 20px;
            border-radius: 5px;
        }

        &__image {
            width: 100%;
            height: 0;
            position: relative;
            padding-bottom: 100%;
            overflow: hidden;
            margin-bottom: 20px;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%,-50%, 0);
                width: 100%;
                height: auto;
            }
        }

        &__idea-title {
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            font-family: $altFont;
            margin-bottom: 10px;
        }

        &__idea-name {
            color: $darkestGray;
            text-transform: uppercase;
            font-family: $altFont;
            margin: 0;
            margin-bottom: 10px;
            text-align: center;
        }

        &__idea-text {
            font-family: $mainFont;
            margin: 0;
            font-weight: 100;
            text-align: center;
        }

        &__paginate{
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 50px;

          li {
            margin: 0 5px;
          }

          a {
            display: flex;
            width: 32px;
            height: 32px;
            background: $white;
            color: $black;
            font-size: 14px;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            text-decoration: none;
          }
        }
    }
}