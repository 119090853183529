.productos {
    background: url('/images/productos/background.jpg') no-repeat center top / cover;
    padding-top: 20vh;

    &__title {
        color: $white;
        font-size: 60px;
        font-family: $altFont;
        text-transform: uppercase;
        text-align: center;
        margin: 0;

        @include mq(md) {
            font-size: 100px;
        }
    }

    &__text {
        color: $white;
        text-align: center;
        font-size: 22px;
        font-family: $altFont;
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 40px;
    }

    &__buttons {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
        flex-wrap: wrap;

        @include mq(md) {
            width: 35%;
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            perspective: 1000;
            display: block;
            width: 100%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            background: none;
            outline: none;
            cursor: pointer;
            
            @include mq(md) {
                width: 48%;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $yellow;
                transform: skewY(-2deg);
            }
    
            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }

            &--white {
                height: 55px;
                line-height: 55px;

                span {
                    background: $white;
                    transform: skewY(1deg);
                }

                p {
                    font-size: 18px;
                }
            }
        }
    }

    &__subtitle {
        text-align: center;
        color: $white;
        text-transform: uppercase;
        font-family: $altFont;
        font-size: 26px;
        margin: 0;
        margin-bottom: 80px;

        @include mq(md) {
            font-size: 40px;
        }
    }

    &__wrapper {
        @include trans;

        opacity: 0;
        overflow: hidden;
        height: 0;

        &.-active {
            opacity: 1;
            height: auto;
        }
    }

    &__container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        @include mq(md) {
            width: 80%;
        }
    }

    &__producto {
        width: 50%;
        overflow: hidden;
        
        @include mq(md) {
            margin-bottom: 40px;
            width: 25%;
        }

        img {
            display: block;
            width: auto;
            width: 50%;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            height: auto;

            @include mq(md) {
                margin-bottom: 40px;
            }
        }

        p {
            text-align: center;
            color: $white;
            text-transform: uppercase;
            font-weight: $altFont;
            font-size: 18px;
            font-weight: 500;
        }
    }
}