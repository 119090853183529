.creaciones {
    &__section-title {
        position: absolute;
        left: 0;
        top: 20%;
        padding: 0 40px;
        height: 40px;
        line-height: 40px;
        background: $blue;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        z-index: 2;
        color: $white;
        font-family: $altFont;
        font-size: 24px;
        text-transform: uppercase;
        margin: 0;

        @include mq(md) {
            height: 60px;
            line-height: 60px;
            font-size: 30px;
            top: 21vh;
        }
    }

    &__hero {
        height: 140vh;
        background: url('/images/reciclado/creaciones-hero-bg.jpg') no-repeat center top / cover;
        position: relative;

        @include mq(md) {
            background: url('/images/reciclado/creaciones-hero-bg.jpg') no-repeat center top / cover;
            height: 140vh;
        }

        &__left-hand,
        &__right-hand {
            position: absolute;
            bottom: 0   ;
            z-index: 10;
            width: 25%;
            display: none;

            @include mq(md) {
                bottom: -50px;
                display: block;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__right-hand{ 
            left: auto;
            right: 0;
        }

        &__title {
            position: absolute;
            top: 20%;
            left: 50%;
            width: 90%;
            transform: translateX(-50%);
            margin: 0;
            text-transform: uppercase;
            color: $red;
            font-size: 26px;
            font-family: $altFont;
            text-align: center;
            
            @include mq(md) {
                max-width: 70%;
                top: 15%;
                font-size: 60px;
                width: 800px;
            }
        }

        &__container {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            height: 20%;

            @include mq(md) {
                width: 50%;
                height: 40%;
                top: 30%;
            }
        }

        &__slider {
            height: 100%;
        }

        &__arrow-prev,
        &__arrow-next {
            @include trans;

            position: absolute;
            width: 60px;
            height: 60px;
            background: $yellow;
            border-radius: 50%;
            z-index: 3;
            cursor: pointer;
            bottom: -230px;
            left: 0;

            @include mq(md) {
                left: -120px;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover {
                .-arrow-prev {
                    left: 0;

                    @include mq(md) {
                        left: -80%;
                    }
                }

                .creaciones__hero__thumbnail-next,
                .creaciones__hero__thumbnail-prev {
                    opacity: 1;
                    transform: translate3d(-50%, -50%, 0) scale3d(3,3,3);
                }
            }

            &.-disabled {
                pointer-events: none;
                opacity: .7;
            }

            .-arrow-prev,
            .-arrow-next {
                @include trans;

                position: absolute;
                z-index: 2;
                left: 0;

                @include mq(md) {
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }

        &__arrow-next {
            left: auto;
            right: 0;

            @include mq(md) {
                right: -120px;
            }
            
            &:hover {
                .-arrow-next {
                    left: 0;

                    @include mq(md) {
                        left: 190%;
                    }
                }
            }
        }

        &__thumbnail-next,
        &__thumbnail-prev {
            @include trans;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 60px;
            height: 60px;
            opacity: 0;
            border-radius: 50%;
            overflow: hidden;
            background: $yellow;
            pointer-events: none;
            display: none;

            @include mq(md) {
                display: block;
            }

            .-thumbnail-next,
            .-thumbnail-prev {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 55px;
                height: 55px;
                border-radius: 50%;
            }
        }

        &__slide {
            position: relative;
            height: 100%;
            overflow: hidden;
            cursor: pointer;


            iframe {
                @include trans;

                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                pointer-events: none;
                border: none;

                &.-active {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            img {
                @include trans;
                
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: auto;
                height: 100%;
            }
        }

        &__text {
            color: $white;
            text-transform: uppercase;
            font-family: $altFont;
            font-size: 18px;
            text-align: center;

            @include mq(md) {
                font-size: 20px;
            }
        }

        &__scroll-button {
            position: absolute;
            bottom: -330px;
            left: 50%;
            transform: translateX(-50%);
            width: 120px;
            height: 120px;
            background: $yellow;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            z-index: 10;

            @include mq(md) {
                bottom: -280px;
            }

            i {
                position: absolute;
                top: -30px;
                font-size: 50px;
                left: 0;
                right: 0;
                width: 50px;
                margin: auto;
                animation: arrowDown .8s ease infinite forwards;
                color: $black;
            }

            p {
                margin: 0;
                text-align: center;
                color: $black; 
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    &__materiales {
        min-height: 100vh;
        background: url('/images/reciclado/creaciones-bottom-bg.png') no-repeat center top / cover;
        margin-top: -250px;
        z-index: 3;
        position: relative; 
        padding-top: 250px;
        overflow: hidden;
        pointer-events: none;

        @include mq(md) {
            margin-top: -200px;
            padding-top: 200px;
            padding-bottom: 100px;
        }

        &__bottom-hand {
            position: absolute;
            left: 10px;
            bottom: -100px;
            display: none;
            
            @include mq(md) {
                bottom: -200px;
                display: block;
            }

            img {
                width: 50%;
                height: auto;

                @include mq(md) {
                    width: 100%;
                }
            }
        }

        &__title {
            text-align: center;
            margin: 0;
            text-transform: uppercase;
            color: $red;
            font-family: $altFont;
            margin-bottom: 40px;
            font-size: 40px;
            
            @include mq(md) {
                font-size: 80px;
            }
        }

        &__container {
            margin-right: auto;
            margin-left: auto;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
            pointer-events: all;
            width: 90%;

            @include mq(md) {
                width: 60%;
            }
        }

        &__material {
            width: 50%;
            padding: 0 10px;
            margin-bottom: 40px;
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            
            @include mq(md) {
                width: 25%;
                min-height: 100px;
            }

            img {
                width: 90%;
                display: block;
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 20px;

                @include mq(md) {
                    width: 70%;
                }

                &.-warning {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: auto;
                }
            }

            p {
                color: $darkestGray;
                text-transform: uppercase;
                font-weight: 500;
                text-align: center;
                margin: 0;
                font-size: 18px;
                font-family: $titleFont;
            }
        }

        &__warning {
            width: 90%;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $white;
            padding: 0 20px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;

            @include mq(md) {
                height: 60px;
                margin-bottom: 0;
                width: 60%;
            }

            img {
                transform: rotate(-20deg);
                height: auto;
                width: 50px;
                margin-right: 20px;

                @include mq(md) {
                    height: 80%;
                    width: auto;
                }
            }

            p {
                font-weight: 500;
                text-transform: uppercase;
                color: $darkestGray;
                white-space: wrap;

                @include mq(md) {
                    white-space: nowrap;
                }
            }
        }
    }
}

.icon-play {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 124px;
    height: 124px;
    line-height: 1.6em;
    z-index: 2;
    margin: -62px 0 0 -62px;
    border: 15px solid #FFF;
    color: #FFF;
    border-radius: 50%;
    font-size: 4em;
    text-align: center;
    text-indent: 5px;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    pointer-events: none;
    
    &.-close {
        opacity: 0;
    }
}

@keyframes arrowDown {
  0%{
      transform: translateY(0);
  }
  100%{
      transform: translateY(10px);
  }
}

