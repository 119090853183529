.contacto {
    padding-top: 150px;
    background: $red url('/images/hablemos/bg.jpg') no-repeat center center / cover;

    &__left,
    &__right {
      position: absolute;
      top: 30%;
      width: 25%;
      max-width: 380px;
    }

    &__left {
      left: 0;
    }

    &__right {
      right: 0;
    }

    &__container {
        margin-left: auto;
        margin-right: auto;
        width: 90%;

        @include mq(md) {
            width: 50%;
        }
    }

    &__title {
        margin: 0;
        text-transform: uppercase;
        text-align: center;
        color: $white;
        font-weight: 500;
        font-size: 40px;
        
        @include mq(md) {
            font-size: 80px;
        }
    }

    &__text {
        text-transform: uppercase;
        text-align: center;
        margin: 0;
        margin-bottom: 40px;
        font-weight: 500;
        color: $white;
        font-size: 20px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 80px;

        &__group {
            width: 100%;
            
            @include mq(md) {
                width: 48%;
            }
            
            &--textarea,
            &--button {
                width: 100%;
            }
        }

        &__input {
            width: 100%;
            height: 50px;
            border-radius: 10px;
            margin-bottom: 15px;
            padding: 20px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            border: 0;
            outline: none;
            font-family: $altFont;
        }

        &__textarea {
            padding: 20px;
            min-height: 100px;
            width: 100%;
            border-radius: 20px;
            text-transform: uppercase;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 20px;
            border: 0;
            outline: none;
            font-family: $altFont;
        }

        &__button {
            border: none;
            text-align: center;
            position: relative;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            height: 60px;
            overflow: hidden;
            line-height: 55px;
            text-decoration: none;
            margin-top: 20px;
            background: transparent;
            
            @include mq(md) {
                width: 20%;
            }

            span {
                position: absolute;
                box-shadow: 0 20px 10px 1px rgba(0,0,0,.15);
                top: -30px;
                left: 0;
                right: 0;
                bottom: 4px;
                background: $yellow;
                transform: skewY(-2deg);
            }

            p {
                font-family: $altFont;
                color: $black;
                z-index: 2;
                position: relative;
                text-transform: uppercase;
                font-size: 20px;
                margin: 0;
                font-weight: 500;
            }
        }
    }
}